// Home
// View style sheet

.clienteRow {
  width: 100% !important;

  .clienteTitle {
    font-size: 0.9rem;
    font-weight: 500;
    color: $fiord-blue;

    i {
      font-size: 1rem;
      cursor: pointer;
    }

    img {
      vertical-align: unset;
      margin-left: 0.35rem;
    }
  }

  .btn-clienteData {
    padding: 0;
    line-height: 0px;
    margin-left: 6px;
    font-size: 1.3rem;
    border: unset;

    i {
      color: lighten($shuttle-gray, 30);
      top: 0;
      transition: all 0.15s ease;
    }

    &:hover, &:focus {
      background-color: lighten($primary, 43) !important;
      box-shadow: 0 0 0 3px lighten($primary, 43) !important;

      i {
        color: $primary;
      }
    }
  }

  .row .col {
    i {
      color: lighten($shuttle-gray, 30);
      font-size: 0.9rem;
      transition: all 0.15s ease;
    }

    .clickableData {
      cursor: default;
      transition: all 0.15s ease;

      &:hover, &:focus {
        background-color: lighten($primary, 43);
        box-shadow:
          -2px 0 0 3px lighten($primary, 43),
          4px 0 0 3px lighten($primary, 43);
        border-radius: 0.25rem;
        color: $primary;
        cursor: pointer;

        i {
          color: $primary;
        }
      }
    }

    .clickableData-disable {
      cursor: default;
      opacity: 0.5;
    }
  }
}

.clienteOptions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  opacity: 0;
  transition: all 0.15s ease;

  button {
    line-height: 0;
    padding: 0.4rem;
    margin-left: 6px;
    transition: 0.15s;

    i {
      top: 0px;
    }

    &:not(:last-child) {
      margin-left: 0;
    }
  }
}
