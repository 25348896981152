//
// Base styles
//

.toast {
  position: absolute;
  display: flex !important;
  z-index: 1;
  top: 0.5rem;
  right: 0.5rem;
  max-width: 300px;
  border: 1px solid;
  padding: 0.5rem 0.8rem;
  box-shadow:
    0 2px 0 rgba(90, 97, 105, 0.11),
    0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06),
    0 7px 70px rgba(90, 97, 105, 0.1);

  .content {
    font-weight: 400;
  }

  .icon {
    display: flex !important;
    padding-right: 0.8rem;
    font-size: 1.5rem;

    i {
      top: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &-center {
    left: 0.5rem;
    right: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}

// Alternate styles
// Override toast theme colors without mixin.
@each $color, $value in $theme-colors {
  .toast-#{$color} {
    background-color: lighten($value, 52);
    border-color: darken($value, 10);

    .content, .icon {
      color: darken($value, 10);
    }

    // Exception for toasts with a light background.
    @if ($color == "primary") or ($color == "warning") {
      background-color: lighten($value, 44);
    }

    // Exception for toasts with a light background.
    @if $color == "warning" {
      border-color: darken($value, 15);

      .content, .icon {
        color: darken($value, 15);
      }
    }
  }
}
