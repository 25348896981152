.login_form {
  max-width: 300px;
}

.login_logo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.25;
  max-height: 60px;
  margin-bottom: 4rem;
  user-select: none;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.login_copyright {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  opacity: 0.5;
}
