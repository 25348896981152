@mixin front-view {
  max-width: 460px;
  min-width: 300px;
}

.modal {
  .modal-content {
    margin-left: auto;
    margin-right: auto;
    @include front-view;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 20px 26px;
    border: none !important;
  }

  .modal-header {
    padding-bottom: 0;

    h5 {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .modal-body {
    padding-top: 0.75rem;
    white-space: pre-wrap;

    strong {
      font-weight: 600;
    }
  }
}

.modal-backdrop {
  background-color: $fiord-blue;
  opacity: 0.4 !important;
}
