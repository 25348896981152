// Seguimiento

.filter-card {
  background-color: lighten($athens-gray, 4);
}

.seguimientos-table {
  border: none !important;
}

.seguimiento-item {
  width: 100%;
  white-space: normal;

  &__header {
    display: flex;
    color: $reagent-gray;
    margin-bottom: 6px;

    &__details {
      align-self: flex-end;
      line-height: 17px;

      a {
        color: $fiord-blue;
        transition: 0.3s;
      }

      a:hover {
        color: $primary;
      }
    }

    &__whats {
      color: $green-whats !important;
      border: 1px solid $green-whats !important;
    }

    &__advertencia {
      color: $orange-warning !important;
      border: 1px solid $orange-warning !important;
    }

    .badge {
      font-weight: 600;
    }

    .skl {
      color: $athens-gray;
    }
  }

  p {
    margin: 0 !important;
    color: $shuttle-gray;
  }

  &__normal {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    background-color: lighten($reagent-gray, 38);
  }

  &__crack {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    background-color: lighten($danger, 52);

    p {
      color: $danger !important;
    }
  }
}
