// Error component

.select-conversacion {
  height: 100vh;
  display: flex;

  &__content {
    padding: 0 0.9375rem;
    display: flex;
    flex-flow: column;
    margin: auto;
    align-items: center;
    text-align: center;
  }

  span {
    font-size: 1.05rem;
    font-weight: 300;
    color: $fiord-blue;
  }
}
