
.react-multi-email {
  height: $rti-height;
  padding: $rti-padding;
  font-size: $rti-font-size;
  line-height: $rti-line-height;
  color: $rti-color;
  background-color: $rti-background-color;
  border: $rti-border;
  font-weight: $rti-font-weight;
  will-change: $rti-will-change;
  border-radius: $rti-border-radius;
  box-shadow: $rti-box-shadow;
  transition: $rti-transition;
}

.react-multi-email > span[data-placeholder] {
  background: $rti-input-background;
  border: $rti-input-border;
  color: $rti-input-color;
  font-size: $rti-input-font-size;
  font-weight: $rti-input-font-weight;
  outline: $rti-input-outline;
  padding: $rti-input-padding;
  width: $rti-input-width;
}
.react-multi-email.focused {
  color: $rti-focused-color;
  background-color: $rti-focused-background-color;
  border-color: $rti-focused-border-color;
  box-shadow: $rti-focused-box-shadow;
}

.react-multi-email.empty > span[data-placeholder] {
  color: $rti-input-plhr-color;
}
.react-multi-email.focused > span[data-placeholder] {
  color: $rti-input-plhr-color;
}

.react-multi-email > input {
  background: $rti-input-background;
  border: $rti-input-border;
  color: $rti-input-color;
  font-size: $rti-input-font-size;
  font-weight: $rti-input-font-weight;
  outline: $rti-input-outline;
  padding: $rti-input-padding;
  width: $rti-input-width;
}

.react-multi-email [data-tag] {
  display: $rti-tag-display;
  background: $rti-tag-background;
  padding: $rti-tag-padding;
  border-radius: $rti-tag-border-radius;
  font-size: $rti-tag-font-size;
  font-weight: $rti-tag-font-weight;
  position: $rti-tag-position;
  border: $rti-tag-border;
  color: $rti-tag-color;
  margin-right: $rti-tag-margin-right;
  margin-bottom: $rti-tag-margin-bottom;

}
// .react-multi-email [data-tag] [data-tag-item] {
//   max-width: 100%;
//   overflow: hidden;
// }
// .react-multi-email [data-tag]:first-child {
//   margin-left: 0;
// }
.react-multi-email [data-tag] [data-tag-handle] {
  cursor: pointer;
  font-weight: bold;
}