.merge-subtitle {
  color: $reagent-gray;
  font-weight: 300;
}

.mergeCliente-info {
  cursor: pointer;
  width: 100% !important;
  transition: all 0.15s ease;

  .clienteTitle {
    font-size: 0.9rem;
    font-weight: 500;
    color: $fiord-blue;

    img {
      vertical-align: unset;
      margin-left: 0.35rem;
    }

    .crack {
      cursor: help;
    }

    .changeCliente {
      position: absolute;
      cursor: default;
      top: 1rem;
      right: 1rem;

      i {
        transition: all 0.25s ease;

        &:hover{
          cursor: pointer;
          color: darken($orange, 4);
          border-radius: 1rem;
          background-color: lighten($orange, 44);
          box-shadow: 0 0 0 2px lighten($orange, 44);
        }
      }
    }
  }

  .expand {
    top: 0px;
  }

  .data span {
    display: block;
    color: $reagent-gray;

    &:first-child {
      color: $shuttle-gray;

      i {
        color: lighten($shuttle-gray, 30);
      }
    }
  }

  i {
    font-size: 1rem;
  }

  &:hover .expand {
    color: darken($primary, 4);
    border-radius: 1rem;
    background-color: lighten($primary, 44);
    box-shadow: 0 0 0 2px lighten($primary, 44);
  }
}

.cardConnector i {
  font-size: 1.2rem;
  color: $reagent-gray;
  transform: rotate(90deg);
}

.clienteMerge-Row {
  width: 100% !important;

  .clienteTitle {
    font-size: 0.9rem;
    font-weight: 500;
    color: $fiord-blue;

    i {
      cursor: help;
    }

    img {
      vertical-align: unset;
      margin-left: 0.35rem;
    }
  }

  .row .col {
    i {
      color: lighten($shuttle-gray, 30);
      font-size: 0.9rem;
      transition: all 0.15s ease;
    }
  }

  .clickableData {
    cursor: default;
    transition: all 0.15s ease;

    &:hover, &:focus {
      background-color: lighten($primary, 43);
      box-shadow:
        2px 0 0 3px lighten($primary, 43),
        -2px 0 0 3px lighten($primary, 43);
      border-radius: 0.25rem;
      color: $primary;
      cursor: pointer;

      i {
        color: $primary;
      }
    }
  }

  .clienteOptions-merge {
    position: absolute;
    top: 1rem;
    right: 1rem;

    button {
      line-height: 0;
      padding: 0.4rem;

      i {
        font-size: 0.9rem;
        top: 0px;
      }
    }
  }
}

.contactoForm {
  .subtitle {
    display: block;
    color: $shuttle-gray;

    i {
      color: lighten($shuttle-gray, 30);
    }
  }

  span {
    color: $reagent-gray;
  }

  label {
    margin-bottom: 0 !important;
  }
}

.stepBar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: $white;
  box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.12);
}

.saldosData {
  display: flex;
  font-size: 0.9rem;
  color: darken($primary, 8);

  label {
    font-size: 0.8rem;
    color: $reagent-gray;
    margin-bottom: 0.2rem !important;
  }

  .mono {
    color: darken($success, 4);
  }

  .multi {
    color: darken($danger, 6);
  }

  .divider {
    color: lighten($reagent-gray, 24);
  }
}

.rfcData {
  div {
    margin-bottom: 0.6rem;
    line-height: normal;
    color: $reagent-gray;

    span:first-child {
      color: $shuttle-gray;
      font-size: 0.85rem;
      letter-spacing: 0.05rem;
    }

    span {
      text-align: left;
      display: block;
    }

    .timbres {
      font-size: 0.85rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.elementos .col {
  span {
    display: block;
    color: darken($primary, 8);
  }

  .mono {
    color: darken($success, 4);
  }

  .multi {
    color: darken($danger, 6);
  }

  .divider {
    color: lighten($reagent-gray, 24);
  }
}
