// Card adjustments

// Block handles
.card-header {
  position: relative;
  border-radius: 0.625rem !important;

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    letter-spacing: 0.05rem;
  }

  &__title:hover {
    cursor: default;
  }

  &__title-collapse:hover {
    cursor: pointer;
  }

  button, .skl-btn {
    height: 28px;
    width: 28px;
    padding: 0.1rem;
    line-height: 0;
    font-size: 0.95rem;
    margin: 0;
    margin-left: 8px;

    &:not([disabled]):active {
      color: $white;
    }

    i {
      top: 0px;
    }
  }

  &__subtitulo {
    font-size: 85%;
    font-weight: 500;
    opacity: 0.7;
    letter-spacing: 1px;

    &_mono{
      color: $primary;
    }

    &_multi{
      color: $success;
    }

    &_vigentes{
      color: $success;
    }

    &_vencidas{
      color: $warning;
    }

    &_sin_licencias{
      color: $primary;
    }

    &_temporal{
      color: $warning;
    }
  }
}

.card .view-report {
  margin-top: auto;
  margin-bottom: auto;
  font-size: $card-view-report-font-size;

  a {
    color: $card-view-report-link-color;

    &:hover {
      color: $card-view-report-link-color-hover;
    }
  }
}

.skl-card-body {
  background-size: cover;
  position: relative;

  .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 0 0 0.625rem 0.625rem;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
}
