// Main footer

.main-footer {
  width: 100%;
  height: $main-footer-height;
  position: absolute;
  font-size: 0.85rem;

  .logo-footer {
    max-height: 20px;
    opacity: 0.7;
    user-select: none;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all 0.25s ease;
  }

  .logo-footer:hover {
    opacity: 1;
    user-select: none;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }

  .copyright {
    padding-top: 0.2rem;
    color: lighten($shuttle-gray, 27);

    a {
      color: lighten($shuttle-gray, 27);
      transition: all 0.25s ease;
    }

    a:hover {
      color: $accent-color;
      text-decoration: none;
    }
  }
}
