// Input groups adjustments

// Set a min width for the input groups.
// Without this some components might break (datepickers).
.input-group {
  min-width: 7.5rem;
}

// Adjust the input group font size.
.input-group-text {
  font-size: $input-font-size;
}

// Adjust the seamless input group - input's padding.
.input-group.input-group-seamless > .form-control:not(:first-child),
.input-group.input-group-seamless > .custom-select:not(:first-child) {
  padding-left: 1.875rem;
}

// Adjust the input group icons.
.input-group-text i {
  transform: scale(1.1);

  &.fa {
    font-size: 0.75rem;
  }

  &.material-icons {
    top: 0;
    font-size: 0.8125rem;
  }
}

.input-negative {
  .prepend {
    color: $input-color !important;
    padding-right: 0px !important;
  }

  .answer {
    padding-left: 1.25rem !important;
  }
}

.input_password {
  user-select: none;
  border-radius: 0 0.25rem 0.25rem 0;

  i {
    margin-right: 0.1rem;
    transition: 0.3s;
  }

  .button_visible {
    position: absolute;
    z-index: -1;
    width: 1.7rem;
    height: 1.7rem;
    top: 0.25rem;
    right: 0.3rem;
    border-radius: 0.25rem;
    transition: 0.3s;
    background-color: $white;
  }

  &:hover {
    cursor: pointer;

    i {
      color: $primary;
    }

    .button_visible {
      background-color: lighten($primary, 40);
    }
  }
}

.input_password_visible {
  user-select: none;

  i {
    color: $white;
    margin-right: 0.1rem;
  }

  .button_visible {
    position: absolute;
    z-index: -1;
    width: 1.7rem;
    height: 1.7rem;
    top: 0.25rem;
    right: 0.3rem;
    border-radius: 0.25rem;
    background-color: $primary;
    transition: 0.3s;
  }

  &:hover {
    cursor: pointer;

    .button_visible {
      background-color: darken($primary, 5);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(0, 123, 255, 0.25);
    }
  }
}

.input_search {
  i {
    margin-right: 0.1rem;
    transition: all 0.3s ease;
  }

  .button_visible {
    position: absolute;
    z-index: -1;
    width: 1.7rem;
    height: 1.7rem;
    top: 0.25rem;
    right: 4.8rem;
    border-radius: 0.25rem;
    transition: 0.3s;
    background-color: $white;
  }

  &:hover {
    cursor: pointer;

    i {
      color: $fiord-blue;
    }

    .button_visible {
      background-color: lighten($fiord-blue, 58);
    }
  }
}
