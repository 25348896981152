// Contextual variations for the new colors
.badge {
  font-family: 'Inter', sans-serif !important;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

@each $color, $value in $new-colors {
  .badge-#{$color} {
    @include badge-variant($value);
    color: darken($value, 12);
    background-color: rgba($value, 0.2);
  }

  .badge-outline-#{$color} {
    background: none;
    border: 1px solid $value;
    color: $value;
  }
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
    color: darken($value, 12);
    background-color: rgba($value, 0.2);
  }
}

// Accent color badge variations
.badge-accent {
  @include badge-variant($accent-color);
}

// Accent color outline badge variation
.badge-outline-accent {
  background: none;
  border: 1px solid $accent-color;
  color: $accent-color;
}

.badge-break {
  white-space: break-spaces;
}
