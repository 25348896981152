// TIMBRES
// RFC's
.rfc {
  width: 100%;
  color: $reagent-gray;

  span:first-child {
    color: $shuttle-gray;
    font-size: 0.85rem;
    letter-spacing: 0.05rem;
  }

  span {
    text-align: left;
    display: block;
  }

  .timbres {
    font-size: 0.85rem;
  }
}

// Historial de Asignacines
.timbres-data, .timbres-ammount {
  width: 100%;

  span {
    display: block;
  }

  .noblock {
    display: inline-flex;
    margin: .5rem 0;
    align-items: end;
  }
}

.timbres-data {
  span:first-child {
    color: $shuttle-gray;
    font-size: 0.85rem;
    letter-spacing: 0.05rem;
  }

  span {
    color: $reagent-gray;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    line-break: normal;
  }
}

.timbres-ammount {
  span {
    text-align: right;
  }

  .ammount {
    font-size: 1rem;
    line-height: 1rem;
  }

  .label {
    font-size: 0.75rem;
    color: $reagent-gray;
    font-weight: 300;
  }

  .hover:hover {
    cursor: pointer;
    color: $warning;
  }

  .danger {
    color: $danger;
  }

}
