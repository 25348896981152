.resume {
  &-row {
    height: 28px;
    display: flex;

    .label {
      width: 30%;
      font-size: 0.75rem;
      font-weight: 500;
      color: $reagent-gray;
      letter-spacing: 0.1rem;
    }

    .content {
      display: flex;
      width: 70%;
      font-size: 0.9rem;
      color: darken($primary, 8);

      .move {
        transform: translateX(1.8rem);
        margin-left: auto;
        transform-origin: right left;
        transition: all 0.3s ease;
      }

      .data {

        .mono {
          color: darken($success, 4);
        }

        .multi {
          color: darken($danger, 6);
        }

        .divider {
          color: lighten($reagent-gray, 24);
        }
      }

      .btn-edit {
        margin-left: 0.4rem;
        padding: 0rem 0rem 0.15rem 0.1rem;
        width: 1.4rem;
        height: 1.4rem;
        opacity: 0;
      }
     }

    &:hover {
      .data {
        transform: translateX(0rem);
      }

      .btn {
        opacity: 1;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }

  .input-edit {
    .input-group-prepend {
      span {
        color: $reagent-gray !important;
      }
    }

    .form-control, .input-group-prepend, .input-group-append {
      font-size: 0.8125rem !important;
      padding-top: 0rem !important;
      padding-bottom: 0rem !important;
      height: 26px !important;
    }

    .input-group-append{
      z-index: 0;

      &:hover {
        z-index: 11;
      }
    }

    .licencias {
      margin-right: 49px;
      right: 0;
      z-index: 1;

      .mono {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1px !important;
      }

      .multi {
        border-radius: 0;
      }

      .form-control:focus {
        z-index: 10;
      }
    }

    .btn {
      width: 1.6rem;
      padding: 0 !important;
      height: auto;
      border-color: #e1e5eb;
      background-color: $white;

      &:hover {
        z-index: 20;
      }

      &-cancel {
        i {
          color: $danger;
        }

        &:hover, &:active {
          background-color: lighten($danger, 50);
          border-color: $danger;
        }
      }

      &-save {
        i {
          color: darken($success, 8);
        }

        &:hover, &:active {
          background-color: lighten($success, 50);
          border-color: darken($success, 8);
        }
      }
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
