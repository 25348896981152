// TABLE
// Component style sheet

// Table row hover
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow) {
  transition: all 0.15s ease;

  &:hover {
    background-color: lighten($fiord-blue, 65) !important;

    .sideTable-options, .clienteOptions, .dropdown-table-options {
      opacity: 1
    }
  }
}

// Table Columns
.sideTable-data, .sideTable-options {
  border-left: none !important;
  border-right: none !important;
}

// Column: sideTable-data
.sideTable-data {
  position: relative;
  width: 100%;
  justify-content: flex-start !important;
  text-align: left !important;

  .content {
    // font-size: $rt-body-td-font-size;
    white-space: break-spaces;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow-y: auto;
    max-height: 100%;
  }
}

// Column: sideTable-options
.sideTable-options {
  display: flex;
  flex: 0 !important;
  width: 100%;
  opacity: 0;
  transition: all 0.15s ease;

  button {
    line-height: 0;
    padding: 0.5rem;
    transition: 0.15s;
    margin-left: 8px;

    i {
      top: 0px;
    }

    &:not(:last-child) {
      margin-left: 0;
    }

    &:active {
      .fa,
      .material-icons {
        color: $white;
      }
    }
  }
}
