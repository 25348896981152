// Dropdown adjustments

// Adjust dropdown icons
.dropdown {
  .dropdown-item, a {
    font-weight: 400;
    color: $fiord-blue;
    text-decoration: none;

    i, .icon-dropdown {
      margin-right: 0.4rem;
      color: lighten($blueish-grey, 20);
      transition: all 0.25s ease;
    }

    &:hover {
      background-color: lighten($fiord-blue, 62);

      i {
        color: $primary;
      }
    }

    &:disabled {
      opacity: 0.4;
    }
  }
}

// Adjust dropdown icons
.menuAgente-dropdown {
  .drop-toggle {
    height: 28px;
    width: 28px;
    padding: 0;
    font-size: 0.9rem;

    i {
      margin-right: 0rem;
      top: 0px;
    }
  }
}

// Block handles
.dropdown-cliente_edit {
  position: absolute !important;
  top: 0.3rem;
  right: 0.3rem;

  .btn_edit {
    font-size: 0.9rem;
    height: 26px;
    width: 26px;
    padding: 0;
    border-radius: 0.6rem;
    border: none !important;
    transition: 0.3s;

    .fa,
    .material-icons {
      color: $blueish-grey !important;
      margin-right: 0rem !important;
    }

    &:hover {
      border: 1px solid $accent-color !important;

      .fa,
      .material-icons {
        color: $accent-color !important;
      }
    }

    &:focus {
      border: 1px solid $accent-color !important;

      .fa,
      .material-icons {
        color: $accent-color !important;
      }
    }
  }
}

.dropdown-table-options, .dropdown-table-options-row {
  width: 100%;
  transition: all .15s ease;

  .drop-toggle {
    line-height: 0;
    padding: 0.3rem;
    transition: 0.3s;
    margin-left: 8px;

    .fa,
    .material-icons {
      margin-right: 0rem;
      top: 0px;
    }

    &:active {
      .fa,
      .material-icons {
        color: $white;
      }
    }
  }

  .dropdown-menu {
    min-width: 8rem;

    .dropdown-item {
      width: 100%;
      color: $fiord-blue;

      .fa,
      .material-icons {
        margin-right: 0.3rem;
      }

      &:hover {
        background-color: lighten($blueish-grey, 60);
      }
    }
  }
}

.dropdown-table-options {
  opacity: 0;
}
